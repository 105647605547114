@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@200;300;400;500;600&display=swap");
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Roboto";
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

.header {
  background-color: #000a1a;
}
.header__container {
  margin: 0 auto;
  background-color: #000a1a;
  color: #f7f9f9;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem;
}
.header__name {
  color: #47ba9f;
  font-weight: 700;
  font-size: 1.75rem;
}
.header__links {
  display: flex;
  gap: 1rem;
}
.header__link {
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 500;
  color: #47ba9f;
}
.header__link:hover {
  text-decoration: underline;
}

.hero {
  width: 100%;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: #000a1a;
  color: #fbfbff;
  padding: 4rem 1.5rem;
}
.hero__photo {
  width: 100px;
  height: 100px;
  background-size: 250%;
  background-image: url(/public/images/me.jpg);
  background-position: top 41.5% left 58%;
  border-radius: 50%;
  border: 5px solid #47ba9f;
}
.hero__name {
  text-align: center;
  font-family: "Roboto";
  color: #f7f9f9;
  font-size: clamp(2rem, 10vw, 4rem);
  padding-top: 1.5rem;
  letter-spacing: 1px;
}
.hero__accent {
  color: #47ba9f;
}
.hero__about {
  font-weight: 500;
  text-align: center;
  padding-bottom: 1.25rem;
}
.hero__description {
  font-size: 1.125rem;
}
.hero__description:nth-of-type(2) {
  padding-top: 0.5rem;
  opacity: 0.6;
}

.skills {
  background-color: #b4b4b4;
  color: #000a1a;
  width: 100%;
  padding: 2rem 1.5rem 4rem;
}
.skills__heading {
  text-align: center;
  color: #000a1a;
}
.skills__ul {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 0.75rem 1rem;
  max-width: 750px;
  margin: 0 auto;
  padding-top: 1rem;
}
.skills__ul__li {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.skills__ul .icon {
  color: #000a1a;
  font-size: 2rem;
}

.projects {
  color: #000a1a;
  padding: 2rem 1.5rem 4rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #fbfbff;
  box-shadow: 0 40px 40px 10px rgba(0, 0, 0, 0.1) inset;
}
.projects__heading {
  margin: 0 auto;
  padding-bottom: 0.75rem;
}
.projects__ul {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin: 0 auto;
}
.projects__show-more {
  text-decoration: underline;
  text-align: center;
  cursor: pointer;
  font-family: "Roboto";
  font-weight: 700;
  font-size: 1.5rem;
}

.project {
  max-width: 750px;
  background-color: #fbfbff;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  box-shadow: 0px 4px 7.5px rgba(0, 0, 0, 0.3);
  transition: 250ms;
}
@media (hover: hover) {
  .project:hover {
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.3);
    transform: scale(1.05);
  }
}
.project__info {
  padding: 1rem;
  font-size: 0.9125rem;
  flex: 1;
}
.project__name {
  text-align: left;
  color: #195d4d;
  font-size: 1.25rem;
}
.project__tech-stack {
  list-style-type: none;
  display: flex;
  gap: 1rem;
  padding-bottom: 0.25em;
}
.project__tech {
  color: #000a1a;
  font-size: 0.75rem;
  text-transform: uppercase;
}
.project__desc {
  color: #000a1a;
  padding-bottom: 1rem;
  text-align: left;
}
.project__links {
  display: flex;
  gap: 1rem;
  align-items: flex-start;
}
.project__link {
  text-decoration: underline;
  color: #000a1a;
  color: #195d4d;
  font-weight: 500;
  border: 2px solid transparent;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  font-size: 0.9125rem;
}
.project__img {
  flex: 0.75;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 2;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.project__img.audiophile {
  background-image: url(../public/images/audiophile.jpg);
  background-size: 150%;
}
.project__img.space {
  background-image: url(../public/images/space.png);
}
.project__img.flags {
  background-image: url(../public/images/flags.png);
  background-position: top 5% left 50%;
}
.project__img.dictionary {
  background-image: url(../public/images/dictionary.png);
  background-position: left 0%;
}

.contact {
  background-color: #000a1a;
  width: 100%;
  color: #f7f9f9;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 2rem 7rem;
  position: relative;
}
.contact__heading {
  padding-bottom: 0.75rem;
}
.contact a {
  text-decoration: none;
  font-size: 1rem;
  cursor: pointer;
  display: inline-block;
  position: relative;
  background: #195d4d;
  color: #fbfbff;
  border: 2px solid transparent;
  padding: 0.5rem 1rem;
  margin-top: 1rem;
  border-radius: 15px;
}
@media (hover: hover) {
  .contact a:hover {
    background: #fbfbff;
    color: #000a1a;
    border: 2px solid #000a1a;
  }
}
.contact:visited {
  color: initial;
}

.footer-form {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.footer-form input {
  font-family: "Century Gothic", CenturyGothic, Geneva, AppleGothic, sans-serif;
  padding: 0.25rem 0.5rem;
  font-size: 1rem;
  border-radius: 5px;
  border: 0;
  border: 2px solid #000a1a;
  color: #000a1a;
}
.footer-form input:focus {
  outline: 0;
  border: 2px solid #47ba9f;
}

.footer-form > * {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 0.25rem;
  margin-bottom: 0.5rem;
}

.message-input {
  resize: none;
  font-family: "Roboto";
  padding: 0.5rem;
  font-size: 1rem;
  border-radius: 5px;
  border: 2px solid #000a1a;
  color: #000a1a;
}
.message-input:focus {
  outline: 0;
  border: 2px solid #47ba9f;
}

.footer-submit-btn {
  cursor: pointer;
  background: #195d4d;
  color: #fbfbff;
  border: 2px solid transparent;
  padding: 0.5rem 1.25rem;
  border-radius: 15px;
  font-weight: 700;
  font-size: 1rem;
  margin-top: 0.5rem;
}
@media (hover: hover) {
  .footer-submit-btn:hover {
    background: #fbfbff;
    color: #000a1a;
    border: 2px solid #000a1a;
  }
}

.footer-arrows {
  position: absolute;
  right: 2rem;
  bottom: 2rem;
}

@media (min-width: 1000px) {
  .header {
    padding-inline: 5rem;
  }
  .hero__photo {
    background-position: top 41.5% left 58%;
    width: 215px;
    height: 215px;
  }
  .footer-form {
    gap: 0.5rem;
  }
  .footer-form > * {
    gap: 0.5rem;
    flex-direction: row;
    justify-content: space-between;
  }
  .double-r {
    font-size: 5vw;
  }
  .nav-item {
    font-size: 1.25rem;
    transition: font-size 1s ease;
  }
  .project-list .tech-slider .tech {
    margin: 0;
  }
  .about-ul {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
  .project-list {
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(4, 1fr);
  }
  .project-desc {
    font-size: 1.25rem;
  }
  .skills .icon {
    font-size: 4rem;
  }
  .skills__ul__li {
    font-size: 1.25rem;
  }
}/*# sourceMappingURL=App.css.map */